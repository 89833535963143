import axios from '@/plugins/axios.js';

export default {
	login: async function(username, password) {
		return await axios.post('/login', { username, password });
	},
	modules: async function() {
		return await axios.get('/modules');
	},
	whoami: async function() {
		return await axios.post('/whoami');
	},
	getReportScoutingNazioni: async function(args) {
    	return await axios.post('/atlas/scouting/report-nazioni', args);
    },
	getOsservatori: async function(args) {
    	return await axios.get('/osservatori/newscout', args);
    }
}