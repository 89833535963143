<script setup>
import { onMounted, shallowRef, watch } from "vue";
import Chart from "chart.js/auto";

const myChart = shallowRef(null);

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    height: {
        type: String,
    default: "300",
    },
    payload: {
        type: Object,
        required: true,
        labels: Array,
        datasets: {
            type: Array,
            label: String,
            data: Array,
        },
    },
    darkMode: {
        type: Boolean
    }
});

const xValue = (ctx, label) => {
  const chart = ctx.chart;
  const dataset = chart.data.datasets[0];
  console.log(dataset.data[chart.data.labels.indexOf(label)]);
  return dataset.data[chart.data.labels.indexOf(label)];
};

const getAvatar = (playerId) => {
  const img = new Image();
  img.src = 'https://domi-be-contabo.chimeralabs.net/avatar/player/' + playerId;
  return img;
}

watch(() => props.darkMode, () => { 
    if(myChart.value) {
        myChart.value.options.scales.x.grid.color = props.darkMode ? '#555' : '#eee';
        myChart.value.update();
    }
});

onMounted(() => {
    var lineChart = document.getElementById(props.id).getContext("2d");

    let chartStatus = Chart.getChart(props.id);
    if (chartStatus != undefined) {
        chartStatus.destroy();
    }

    myChart.value = new Chart(lineChart, {
        type: 'bar',
        data: {
            labels: props.payload.labels,
            datasets: props.payload.datasets,
        },
        options: {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: "#aaa",
                    }
                },
                annotation: {
                    annotations: {
                        label1: {
                            type: 'label',
                        //    borderColor: (ctx) => ctx.chart.data.datasets[0].backgroundColor,
                            borderColor: '#eee',
                            backgroundColor: '#eee',
                            padding: 0,
                            borderRadius: 100, 
                            width: 40,
                            height: 40,
                            borderWidth: 1,
                            content: getAvatar(1649),
                            position: {
                                x: 'end',
                                y: 'center'
                            },
                            yValue: 'Jun',
                            xValue: (ctx) => xValue(ctx, 'Jun'),
                            xAdjust: 40,
                        },
                        label2: {
                            type: 'label',
                        //    borderColor: (ctx) => ctx.chart.data.datasets[0].backgroundColor,
                            borderColor: '#eee',
                            backgroundColor: '#eee',
                            padding: 0,
                            borderRadius: 100, 
                            width: 40,
                            height: 40,
                            borderWidth: 1,
                            content: getAvatar(1367),
                            position: {
                                x: 'end',
                                y: 'center'
                            },
                            yValue: 'Aug',
                            xValue: (ctx) => xValue(ctx, 'Aug'),
                            xAdjust: 40,
                        },
                        label3: {
                            type: 'label',
                        //    borderColor: (ctx) => ctx.chart.data.datasets[0].backgroundColor,
                            borderColor: '#eee',
                            backgroundColor: '#eee',
                            padding: 0,
                            borderRadius: 100, 
                            width: 40,
                            height: 40,
                            borderWidth: 1,
                            content: getAvatar(8177),
                            position: {
                                x: 'end',
                                y: 'center'
                            },
                            yValue: 'Oct',
                            xValue: (ctx) => xValue(ctx, 'Oct'),
                            xAdjust: 40,
                        }
                    }
                },
            },
            interaction: {
                intersect: false,
                mode: "index",
            },
            scales: {
                x: {
                    grid: {
                        drawTicks: false,
                        color: props.darkMode ? '#555' : '#eee', 
                    },
                    ticks: {
                        display: true,
                        color: "#aaa",
                    }
                }, 
                y: {
                    grid: {
                        display: false,
                        color: "#eee",
                    },
                    ticks: {
                        display: true,
                        color: "#aaa",
                    }
                }
            }
        }
    });
});
</script>
<template>
<div class="chart">
<canvas
:id="props.id"
class="chart-canvas"
:height="props.height"
></canvas>
</div>
</template>
