<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
//import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
//import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import showSwal from "@/mixins/showSwal.js";
const body = document.getElementsByTagName("body")[0];

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});
</script>
<template>
  <!--div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div-->
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
              <div class="card">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Login</h4>
                  <p class="mb-0">Inserisci username and password per loggarti</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="login">
                    <div class="mb-3">
                      <argon-input
                        id="username"
                        type="text"
                        placeholder="Username"
                        name="username"
                        size="lg"
                        v-model="username"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        v-model="password"
                      />
                    </div>
                    <!--argon-switch id="rememberMe" name="remember-me"
                      >Remember me</argon-switch
                    -->

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="dark"
                        fullWidth
                        size="lg"
                        >Accedi</argon-button
                      >
                    </div>
                  </form>
                </div>
                <!--div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a
                      href="javascript:;"
                      class="text-success text-gradient font-weight-bold"
                      >Sign up</a
                    >
                  </p>
                </div-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>

export default {
  data: function() {
    return {
      username: '',
      password: '',
      fromSession: false
    }
  },
  mixins: [showSwal],
  methods: {
    async login() {
      try{
        await this.$store.dispatch("user/login", { username: this.username, password: this.password });
      }
      catch(error) {
        this.showSwal({
          type:"error",
          message: Object.values(error.response.data),
          icon: 'error'
        });
      }
    },

    fromStorage: function() {
      var t = window.localStorage.getItem('user');
      if(t != null) {
        this.fromSession = true;
        this.restoreSession();
      }

    },
    restoreSession: function() {
      this.$store.dispatch('user/restore', JSON.parse(window.localStorage.getItem('user')));
    }
  },
  created: function() {
    this.fromStorage();
  },
  computed: {
    logged: function() {
      return this.$store.getters['user/token'] || false;
    }
  },
  watch: {
    logged: {
      immediate: true,
      deep: true,
      handler: function() {
        if(this.logged != false)
          this.$router.push({name: 'Home'});
      }
    }
  }
};
</script>

