import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import axios from './plugins/axios.js';
import VueAxios from 'vue-axios';
import VueSmartWidget from 'vue-smart-widget';
import '../node_modules/vue-smart-widget/lib/style.css';

const appInstance = createApp(App);
appInstance.use(VueAxios, axios);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(VueSmartWidget);
appInstance.mount("#app");
