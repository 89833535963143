import axios from '@/plugins/axios.js';

export default {
    askQuestion(question) {
        return axios.post('/jai/ask/question', {question}).then(res => res.data);
    }
/*  getPlayersMatches(team_id, season_id) {
        return axios.get('/report/players/matches', {params: { team_id: team_id, season_id: season_id }}).then(res => res.data);
	};
    getPlayersAttendanceMatches(team_id, season_id) {
        return axios.get('/report/attendances/players/matches', {params: { team_id: team_id, season_id: season_id } }).then(res => res.data);
    };
    getPlayersOnLoan(from, to, only_active) {
        return axios.get('/report/players/onloan', {params: {from: from, to: to, only_active: only_active }}).then(res => res.data);
    };
    savePersonePreset(chiave, persone) {
        return axios.post('/atlas/dashboard/set-persone', { chiave, persone }).then(res => res.data);
    }; */
}