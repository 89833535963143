import axios from 'axios';
import store from '@/store';
import router from '@/router';

axios.interceptors.request.use(
 	function(config) {

 		config.baseURL = store.state.apiEndPoint; // 👈🏻 Base Url :)

		if(store.getters['user/token'])
 			config.headers.tokenauth = 'Bearer ' + store.getters['user/token'];

 		return config;
 		
 	},
 	function(error) {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
 	function(response) {
 		return response;
 	},
 	function(error) {

 		if(error.response.status == 401) {
 			store.dispatch('user/logout');
 			router.replace("/login");
 		}

		return Promise.reject(error);

	}
);
export default axios;