<script setup>
const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    height: {
        type: String,
        default: "300",
    },
    title: {
        type: String,
        default: "",
    },
    description: {
        type: String,
        default: "",
    },
    fields: Array,
    items: Array,
});
</script>
<template>
<div class="card mb-4">
<div class="card-header pb-0">
<h6>{{ props.title }}</h6>
</div>
<div class="card-body px-0 pt-0 pb-2">
<div class="table-responsive p-0">
<table class="table align-items-center justify-content-center mb-0">
<thead>
<tr>
<th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" v-for="field in fields" :key="field">{{ field }}</th>
</tr>
</thead>
<tbody>
<tr v-for="item, index in items" :key="index">
<td v-for="field in fields" :key="field + '_' + index" class="align-middle text-center text-sm">
<div class="px-2 py-1">
{{ item[field]}}
</div>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</template>
