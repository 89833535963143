<script setup>
const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    height: {
        type: String,
        default: "300",
    },
    description: {
        type: String,
        default: "",
    },
    payload: {
        fields: Array,
        items: Array,
    },
    contentHeight: {
        type: Number
    },
    isFullscreen: {
        type: Boolean
    }
});
</script>
<template>
<div class="table-responsive p-0" :style="'max-height:' + (contentHeight && !isFullscreen ? (contentHeight - 70) + 'px' : '70vh')">
<table class="table align-items-center justify-content-center mb-0 table-striped">
<thead class="sticky-top table-dark">
<tr>
<th class="text-uppercase text-xxs font-weight-bolder opacity-9" v-for="field in props.payload.fields" :key="field">{{ field }}</th>
</tr>
</thead>
<tbody>
<tr v-for="item, n in props.payload.items" :key="n">
<td v-for="field, index in props.payload.fields" :key="field + '_' + index" class="align-middle text-sm">
<div class="d-flex px-2 py-1">
<div v-if="field == 'player'"><img :src="'https://domi-be-contabo.chimeralabs.net/avatar/player/' + item['player_id']"
    class="avatar avatar-sm me-3 player-avatar"
    alt="user1"
/></div>
<div v-if="field == 'team'"><img :src="'https://domi-be-contabo.chimeralabs.net/logo/team/' + item['team_id']"
    class="avatar avatar-sm me-3"
    alt="user1"
/></div>
<div class="d-flex flex-column justify-content-center">
{{ item[field]}}
</div>
</div>
</td>
</tr>
</tbody>
</table>
</div>
</template>
