<script setup>
/* eslint-disable no-unused-vars */
import { useStore } from "vuex";
import { computed, watch } from 'vue';
import LineChart from "@/examples/Charts/LineChart.vue";
import BarChart from "@/examples/Charts/BarChart.vue";
import BarChartHorizontal from "@/examples/Charts/BarChartHorizontal.vue";
import TableData from "@/examples/TableData.vue";
import DoughnutChart from "@/examples/Charts/DoughnutChart.vue";
import PolarChart from "@/examples/Charts/PolarChart.vue";
import RadarChart from "@/examples/Charts/RadarChart.vue";
import BubbleChart from "@/examples/Charts/BubbleChart.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import eChart from '@/examples/Charts/Echart.vue';
import PolarCategory from '@/examples/Charts/PolarCategory.vue';

const components = {
  'LineChart': LineChart,
  'BarChart': BarChart,
  'TableData': TableData,
  'BarChartHorizontal': BarChartHorizontal,
  'DoughnutChart': DoughnutChart,
  'PolarChart': PolarChart,
  'RadarChart': RadarChart,
  'BubbleChart': BubbleChart,
  'eChart': eChart,
  'PolarCategory': PolarCategory
}

const store = useStore();

const darkMode = computed(() => store.state.darkMode);

const toggleFullscreen = (widget) => {
    widget.fullscreen = !widget.fullscreen;
    store.commit("setSidenav", !widget.fullscreen);
};

const currentWidget = computed(() => {
    return store.getters['jai/currentWidget'] ? store.getters['jai/currentWidget'] : false;
});

const colors = [
    "#00B386",
    "#2EC85B",
    "#7BDE3C",
    "#B8F100",
    "#E9F700",
    "#FFF300",
    "#FFDB00",
    "#FFB800",
    "#FF8900",
    "#FF0059",
];

const colors_rgb = [
    "rgba(0, 179, 134, 0.7)",
    "rgba(46, 200, 91, 0.7)",
    "rgba(123, 222, 60, 0.7)",
    "rgba(184, 241, 0, 0.7)",
    "rgba(233, 247, 0, 0.7)",
    "rgba(255, 243, 0, 0.7)",
    "rgba(255, 219, 0, 0.7)",
    "rgba(255, 184, 0, 0.7)",
    "rgba(255, 137, 0, 0.7)",
    "rgba(255, 0, 89, 0.7)",
];

const widgets = [
    { x: 0, y: 0, w: 4, h: 8, i: 'chart-line-0', title: 'Line Chart',  component: 'LineChart', fullscreen: false, payload: {
        labels: [
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
            ],
        datasets: [
        {
            label: 'Dataset 1',
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
            borderColor: colors[2],
            backgroundColor: colors[2], 
            tension: 0.4
        },
        {
            label: 'Dataset 2',
            data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
            borderColor: colors[5], 
            backgroundColor: colors[5], 
            tension: 0.4
        },
        ],
    }},
/*  { x: 0, y: 0, w: 4, h: 8, i: 'chart-line-0', title: 'Line Chart',  component: 'eChart', fullscreen: false, payload: {
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      smooth: true
    }
  ]
}},  */
/*    { x: 0, y: 0, w: 4, h: 8, i: 'chart-line-0', title: 'Polar Chart',  component: 'PolarCategory', fullscreen: false, payload: {
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      smooth: true
    }
  ]
}},  */
    { x: 4, y: 0, w: 4, h: 8, i: 'chart-bar-1', title: 'Bar Chart', component: 'BarChart', fullscreen: false, payload: {
    labels: [
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
        borderColor: colors[8],
        backgroundColor: colors[8], 
    },
    {
        label: 'Dataset 2',
        data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
        borderColor: colors[1],
        backgroundColor: colors[1], 
    },
    ],
}},
    { x: 8, y: 0, w: 4, h: 8, i: 'table-data-2', title: 'Simple Data Table', component: 'TableData', fullscreen: false, payload: {
        fields: ['player', 'team', 'goals', 'assists'],
        items: [{ player: 'Pinco Pallino', player_id: 1649, team: 'Rossi', team_id: 45, goals: 5, assists: 3 },{ player: 'Mario Rossi', player_id: 1367, team: 'Gialli', team_id: 189, goals: 8, assists: 7 }, { player: 'Paolo Verdi', player_id: 8177, team: 'Arancioni', team_id: 196,  goals: 0, assists: 3 }, { player: 'Pinco Pallino', player_id: 6987, team: 'Rossi', team_id: 138, goals: 5, assists: 3 },{ player: 'Mario Rossi', player_id: 4495, team: 'Gialli', team_id: 212, goals: 8, assists: 7 }, { player: 'Paolo Verdi', player_id: 3576, team: 'Arancioni', team_id: 127, goals: 0, assists: 3 }],
    } },
    { x: 0, y: 3, w: 4, h: 8, i: 'chart-bar-horizontal-3', title: 'Bar Chart Horizontal', component: 'BarChartHorizontal', payload: {
    labels: [
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [500, 450, 400, 350, 300, 250, 200, 150, 100, 50],
        borderColor: colors,
        backgroundColor: colors, 
    },
/*    {
        label: 'Dataset 2',
        data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
        borderColor: colors[3],
        backgroundColor: colors[3], 
    },  */
    ],
}},
    { x: 4, y: 3, w: 4, h: 8, i: 'chart-pie-4', title: 'Doughnut Chart', component: 'DoughnutChart', fullscreen: false, payload: {
    labels: [
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [420, 350, 300, 220, 500, 250, 400, 230, 500, 250],
   //     borderColor: 'rgba(0, 0, 0, 0.1)',
        borderColor: colors, 
        backgroundColor: colors, 
        hoverOffset: 5
    },
    ],
} },
    { x: 8, y: 3, w: 4, h: 8, i: 'chart-polar-5', title: 'Polar Chart', component: 'PolarChart', fullscreen: false, payload: {
    labels: [
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [420, 350, 300, 220, 500, 250, 400, 230, 500, 250],
        borderColor: colors_rgb, 
        backgroundColor: colors_rgb, 
        hoverOffset: 5
    },
    ],
} },
    { x: 0, y: 6, w: 4, h: 8, i: 'chart-radar-6', title: 'Radar Chart', component: 'RadarChart', fullscreen: false, payload: {
    labels: [
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [270, 480, 300, 390, 500, 600, 400, 340, 500],
        borderColor: colors_rgb[2],
        backgroundColor: colors_rgb[2], 
    },
    {
        label: 'Dataset 2',
        data: [430, 380, 590, 450, 350, 510, 530, 540, 640],
        borderColor: colors_rgb[7],
        backgroundColor: colors_rgb[7], 
    },
    ],
} },
    { x: 4, y: 6, w: 4, h: 8, i: 'chart-mixed-7', title: 'Mixed Chart', component: 'BarChart', fullscreen: false, payload: {
    labels: [
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
        borderColor: colors[8],
        backgroundColor: colors[8], 
        type: 'line',
        tension: 0.4,
    },
    {
        label: 'Dataset 2',
        data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
        borderColor: colors[3],
        backgroundColor: colors[3], 
    },
    ],
} },
    { x: 8, y: 6, w: 4, h: 8, i: 'chart-bubble-8', title: 'Bubble Chart', component: 'BubbleChart', fullscreen: false, payload: {
    datasets: [
    {
        label: 'Dataset 1',
        data: [{
            x: 50,
            y: 34,
            r: 15
        }, {
            x: 40,
            y: 63,
            r: 10
        }, {
            x: 300,
            y: 200,
            r: 10
        }, {
            x: 220,
            y: 110,
            r: 12
        },{
            x: 350,
            y: 500,
            r: 12
        }, {
            x: 150,
            y: 400,
            r: 10
        }],
        borderColor: colors[9],
        backgroundColor: colors_rgb[9], 
    },
    {
        label: 'Dataset 2',
        data: [{
            x: 500,
            y: 350,
            r: 15
        }, {
            x: 250,
            y: 120,
            r: 10
        }, {
            x: 400,
            y: 230,
            r: 9
        }, {
            x: 500,
            y: 640,
            r: 12
        } , {
            x: 400,
            y: 150,
            r: 10
        }],
        borderColor: colors[4],
        backgroundColor: colors_rgb[4], 
    },
    ],
}}, 
    ] 
</script>
<template>
<div class="py-4 container-fluid">
<div class="row">
<div class="col-lg-12">

<div class="row" v-if="currentWidget && !currentWidget.id">
<div class="col-12 mb-4">
<div class="card">
<div class="card-header pb-0">
<div class="d-flex align-items-center justify-content-between">
<h6 class="mb-0">Nuovo Widget</h6>
<div>
<argon-button color="success" size="sm" class="ms-auto me-2">Salva</argon-button>
<argon-button color="secondary" size="sm" class="ms-auto" @click.stop="store.commit('jai/setWidget', null)">Chiudi</argon-button>
</div>
</div>
</div>
<div class="card-body">{{ store.getters['jai/currentWidget'] }}</div>
</div>
</div>
</div>

<smart-widget-grid :layout="widgets">
<template v-slot:[widget.i] v-for="widget in widgets" :key="widget.i">
<smart-widget :title="widget.title" :fullscreen="true" @before-fullscreen="toggleFullscreen(widget)">

<template v-slot:toolbar>
<div style="margin: 0 12px;" v-if="widget.fullscreen">
<argon-button color="success" size="sm" class="px-3 me-2"><i class="fa fa-pencil" aria-hidden="true"></i></argon-button>
<argon-button color="danger" size="sm" class="px-3"><i class="fa fa-trash" aria-hidden="true"></i></argon-button>
</div>
</template>

<template v-slot="{contentH}">
    <div class="content-widget">
        <component :is="components[widget.component]" :payload="widget.payload" :id="widget.i" :contentHeight="contentH" :darkMode="darkMode"/>                                                  
    </div>
</template>

</smart-widget>
</template>
</smart-widget-grid>


</div>
</div>
</div>
</template>
