import api from '@/api/users.js';

import _ from 'lodash';

export default {
	namespaced: true,
	state: function() {
		return {
		//	logged: false,
		//	token: false,
			user: window.localStorage.getItem(`user`) ? JSON.parse(window.localStorage.getItem(`user`)) : false,
			language: 'it'
		}
	},
	mutations: {
		setUser: function(state, info) {
		//	state.logged = true;
		//	state.token = info.data.api_token;
			state.user = info.data;
		},
		unsetUser: function(state) {
		//	state.logged = false;
		//	state.token = false;
			state.user = false;
		},
		setLanguage: function(state, lang) {
			state.language = lang;
		}
	},
	actions: {
	/*	checkLocalStorage: function(state) {
			console.log(window.localStorage.user); 
		    if (window.localStorage.user) {
		      state.user = JSON.parse(localStorage.user);
		      state.token = state.user.api_token;
		      state.logged = true;

		      console.log(state.user);
		      return true;
		    } else {
		      state.user = false;
		      state.token = false;
		      state.logged = false;
		      return false;
		    }
		}, */
		login: async function(state, args) {
			var info = await api.login(args.username, args.password);
			state.commit('setUser', info);

			window.localStorage.setItem("user", JSON.stringify(info.data));
		},
		restore: async function(state, args) {
			state.commit('setUser', { data: args });
		},
		logout: function(state) {
			state.commit('unsetUser');

			var t = window.localStorage.getItem('user');
			if(t != null)
				window.localStorage.removeItem('user');

		},
		language: function(state, args) {
			state.commit('setLanguage', args);
		},
	},
	getters: {
		user: function(state) {
			return state.user;
		},
		logged: function(state) {
			if(state.user && state.user.api_token) {
				return true;
			}
			return false;
		},
		language: function(state) {
			return state.language || 'it'
		},
		rights: function(state) {
			if(state.logged) {
				var permessiGruppi = _.map(_.flatten(_.map(state.user.gruppi, 'permessi')), 'nome');
				var permessiUtente = _.map(state.user.permessi, 'nome');
				return _.union(permessiGruppi, permessiUtente);
			}
			return false;
		},
		token: function(state) {
			return state.user && state.user.api_token ? state.user.api_token : false;
		}
	}
}