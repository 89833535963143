<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import Speech from "@/components/Speech.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const showMenu = ref(false);
const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const router = useRouter();
const route = useRoute();

const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.commit("sidebarMinimize");
//const toggleConfigurator = () => store.commit("toggleConfigurator");

const closeMenu = () => {
  setTimeout(() => {
    showMenu.value = false;
  }, 100);
};

const txt = ref('');
const isListening = ref(false);

const addText = (text) => {
//txt.value += ' ' + text;
  txt.value = text;
};

const setListening = (value) => {
  isListening.value = value;
};

const logout = () => {
  store.dispatch('user/logout');
  store.commit('jai/setWidget', null);
  router.push({name: 'Login'});
};

const askQuestion = () => {
  store.dispatch('jai/askQuestion', txt.value);
};

</script>
<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />


      <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"><a href="#" @click="minimizeSidebar" class="p-0 nav-link text-white" id="iconNavbarSidenav"><div class="sidenav-toggler-inner"><i class="sidenav-toggler-line bg-white"></i><i class="sidenav-toggler-line bg-white"></i><i class="sidenav-toggler-line bg-white"></i></div></a></div>
      
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 d-flex justify-content-between"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >

      <div class="d-flex w-80 mx-2 align-items-center">
         <textarea
        class="form-control"
        label="Speech to Text"
        v-model="txt"
        placeholder="Chiedimi pure..."
        rows="3"
      ></textarea>


      <Speech :addText="addText" :setListening="setListening" class="ms-2"/>

      <argon-button @click.stop="askQuestion" :disabled="txt.trim() == '' || isListening" class="ms-2"><i class="fa fa-paper-plane" aria-hidden="true"></i></argon-button>

      </div>

        <!--div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >

          <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'"
            />
          </div>
        </div-->
        <ul class="navbar-nav justify-content-end">
          <!--li class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Signin' }"
              class="px-0 nav-link font-weight-bold text-white"
              target="_blank"
            >
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-2'"></i>
              <span v-if="isRTL" class="d-sm-inline d-none">يسجل دخول</span>
              <span v-else class="d-sm-inline d-none">{{ $store.getters['user/user'].nomeCompleto }}</span>
            </router-link>
          </li>
          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="toggleConfigurator">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li-->
          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              href="#"
              class="p-0 nav-link text-white"
              :class="[showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
              @blur="closeMenu"
            >
            {{ $store.getters['user/user'].nomeCompleto }}

              <i class="cursor-pointer fa fa-caret-down"></i>
            </a>
            <ul
              class="px-0 py-0 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;" @click.stop="logout">
                  <div class="py-1 d-flex">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        Logout
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
