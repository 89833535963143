<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div>
<argon-button :color="!isListening ? 'primary' : 'danger'" @click.stop="toggleListening">
<i v-if="isListening" class="fa fa-microphone" aria-hidden="true"></i>
<i v-else class="fa fa-microphone-slash" aria-hidden="true"></i>
</argon-button>
</div>
</template>

<script>
export default {
  props: {
    addText: {
      type: Function,
      required: true,
    },
    setListening: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      speechToText: null,
      isListening: false,
      recognition: null,
    };
  },
  methods: {
    toggleListening() {
      this.isListening = !this.isListening;
    },

    record(event) {
      const transcript = Array.from(event.results).map((result) => result[0]).map((result) => result.transcript).join("");
      this.addText(transcript);
    },
  },

  watch: {
    isListening: function() {
      if (this.isListening) {
        this.recognition.start();
      } else {
        this.recognition.stop();
      }
      this.setListening(this.isListening);
    }
  },

  mounted() {
    this.recognition = new window.webkitSpeechRecognition();
    this.recognition.lang = "it-IT";
    this.recognition.interimResults = true;
    this.recognition.continuous = true;
    this.recognition.onresult = this.record;
  },
};
</script>