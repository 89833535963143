<template>
    <div style="width: 100%; height: 300px"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    props: [ 'payload', 'contentHeight' ],
    data: function() {
        return {
            chart: null
        }
    },
    watch: {
        'payload': {
            deep: true,
            handler: function() {
                this.generateGraph();
            }
        },
        contentHeight() {
            this.chart.resize();
        },
    },
    mounted: function() {
        this.generateGraph();
    },
    methods: {
        generateGraph: function() {

            if(this.chart == null) {
                var chartDom = this.$el;
                var eChart = echarts.init(chartDom);
                this.chart = eChart;
            } else {
                eChart = this.chart;
            }
            
            eChart.setOption(this.payload);

            eChart.on('click', function (params) {
                this.$emit('onClick', params);    
            }.bind(this));
        }
    }
}
</script>
