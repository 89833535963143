import api from '@/api/jai.js';

export default {
	namespaced: true,
	state: function() {
		return {
			widgets: [],
			currentWidget: null,	
		}
	},
	mutations: {
		setWidget: function(state, data) {
		//	console.log(data);
			state.currentWidget = data;
		},
	},
	actions: {
		askQuestion: async function(state, args) {
			var data = await api.askQuestion(args);
		//	console.log(data);
			state.commit('setWidget', data);
		},
	},
	getters: {
		currentWidget: function(state) {
			return state.currentWidget;
		},
	}
}