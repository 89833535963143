<script setup>
import { onMounted } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    height: {
        type: String,
    default: "300",
    },
    payload: {
        type: Object,
        required: true,
        labels: Array,
        datasets: {
            type: Array,
            label: String,
            data: Array,
        },
    },
});

onMounted(() => {
    var lineChart = document.getElementById(props.id).getContext("2d");

    let chartStatus = Chart.getChart(props.id);
    if (chartStatus != undefined) {
        chartStatus.destroy();
    }

    new Chart(lineChart, {
        type: 'doughnut',
        data: {
            labels: props.payload.labels,
            datasets: props.payload.datasets,
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: "#aaa",
                    }
                },
            },
            interaction: {
                intersect: false,
                mode: "index",
            },
        /*  scales: {
                y: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    border: {
                        dash: [5, 5],
                    },
                    ticks: {
                        display: true,
                        padding: 10,
                        color: "#fbfbfb",
                        font: {
                            size: 11,
                            family: "Open Sans",
                            style: "normal",
                            lineHeight: 2,
                        },
                    },
                },
                x: {
                    grid: {
                        drawBorder: false,
                        display: false,
                        drawOnChartArea: false,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    border: {
                        dash: [5, 5],
                    },
                    ticks: {
                        display: true,
                        color: "#ccc",
                        padding: 20,
                        font: {
                            size: 11,
                            family: "Open Sans",
                            style: "normal",
                            lineHeight: 2,
                        },
                    },

                } 
            }  */
        }
    });
});
</script>
<template>
<div class="chart">
<canvas
:id="props.id"
class="chart-canvas"
:height="props.height"
></canvas>
</div>
</template>
