<script setup>
import { useStore } from "vuex";
import { computed } from 'vue';
import LineChart from "@/examples/Charts/LineChartCard.vue";
import BarChart from "@/examples/Charts/BarChartCard.vue";
import BarChartHorizontal from "@/examples/Charts/BarChartHorizontalCard.vue";
import TableData from "@/examples/TableDataCard.vue";
import PieChart from "@/examples/Charts/PieChartCard.vue";
import PolarChart from "@/examples/Charts/PolarChartCard.vue";
import RadarChart from "@/examples/Charts/RadarChartCard.vue";
import BubbleChart from "@/examples/Charts/BubbleChartCard.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const store = useStore();

const currentWidget = computed(() => {
  return store.getters['jai/currentWidget'] ? store.getters['jai/currentWidget'] : false;
});

</script>
<template>
<div class="py-4 container-fluid">
<div class="row">
<div class="col-lg-12">

<div class="row" v-if="currentWidget">
<div class="col-12 mb-4">
<div class="card">
<div class="card-header pb-0">
    <div class="d-flex align-items-center justify-content-between">
        <h6 class="mb-0">{{ currentWidget.id ? 'Modifica Widget' : 'Nuovo Widget' }}</h6>
        <div>
            <argon-button color="success" size="sm" class="ms-auto me-2">Salva</argon-button>
            <argon-button v-if="currentWidget.id" color="danger" size="sm" class="ms-auto me-2">Elimina</argon-button>
            <argon-button color="secondary" size="sm" class="ms-auto" @click.stop="store.commit('jai/setWidget', null)">Chiudi</argon-button>
        </div>
    </div>
</div>
<div class="card-body">{{ store.getters['jai/currentWidget'] }}</div>
</div>
</div>
</div>


<div class="row">
<div class="col-lg-4 col-md-6 col-12 mb-4">
<line-chart
id="chart-line"
title="Line Chart"
:chart="{
    labels: [
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
        tension: 0.4
    },
    {
        label: 'Dataset 2',
        data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
        tension: 0.4
    },
    ],
}"
/>
</div>
<div class="col-lg-4 col-md-6 col-12 mb-4">
<bar-chart
id="chart-bar"
title="Bar Chart"
:chart="{
    labels: [
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
        tension: 0.4
    },
    {
        label: 'Dataset 2',
        data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
        tension: 0.4
    },
    ],
}"
/>
</div>
<div class="col-lg-4 col-md-6 col-12 mb-4">
<table-data id="mytable" title="Simple Table" :fields="['player', 'team', 'goals', 'assists']" :items="[{ player: 'Pinco Pallino', team: 'Rossi', goals: 5, assists: 3 },{ player: 'Mario Rossi', team: 'Gialli', goals: 8, assists: 7 }, { player: 'Paolo Verdi', team: 'Arancioni', goals: 0, assists: 3 }, { player: 'Pinco Pallino', team: 'Rossi', goals: 5, assists: 3 },{ player: 'Mario Rossi', team: 'Gialli', goals: 8, assists: 7 }, { player: 'Paolo Verdi', team: 'Arancioni', goals: 0, assists: 3 }]"/>
</div>
<div class="col-lg-4 col-md-6 col-12 mb-4">
<bar-chart-horizontal
id="chart-bar-horizontal"
title="Bar Chart Horizontal"
:chart="{
    labels: [
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
        tension: 0.4
    },
    {
        label: 'Dataset 2',
        data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
        tension: 0.4
    },
    ],
}"
/>
</div>

<div class="col-lg-4 col-md-6 col-12 mb-4">
<pie-chart
id="chart-pie"
title="Pie Chart"
:chart="{
    labels: [
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
    },
    ],
}"
/>
</div>

<div class="col-lg-4 col-md-6 col-12 mb-4">
<polar-chart
id="chart-polar"
title="Polar Chart"
:chart="{
    labels: [
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
    },
    ],
}"
/>
</div>

<div class="col-lg-4 col-md-6 col-12 mb-4">
<radar-chart
id="chart-radar"
title="Radar Chart"
:chart="{
    labels: [
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
    },
    {
        label: 'Dataset 2',
        data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
        tension: 0.4
    },
    ],
}"
/>
</div>

<div class="col-lg-4 col-md-6 col-12 mb-4">
<bar-chart
id="chart-mixed"
title="Mixed Chart"
:chart="{
    labels: [
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    ],
    datasets: [
    {
        label: 'Dataset 1',
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
    },
    {
        label: 'Dataset 2',
        data: [34, 63, 200, 110, 350, 120, 230, 110, 640],
        type: 'line',
        tension: 0.4
    },
    ],
}"
/>
</div>

<div class="col-lg-4 col-md-6 col-12 mb-4">
<bubble-chart
id="chart-bubble"
title="Bubble Chart"
:chart="{
    datasets: [
    {
        label: 'Dataset 1',
        data: [{
            x: 50,
            y: 34,
            r: 15
        }, {
            x: 40,
            y: 63,
            r: 10
        }, {
            x: 300,
            y: 200,
            r: 10
        }, {
            x: 220,
            y: 110,
            r: 12
        },{
            x: 350,
            y: 500,
            r: 12
        }, {
            x: 150,
            y: 400,
            r: 10
        }],
    },
    {
        label: 'Dataset 2',
        data: [{
            x: 500,
            y: 350,
            r: 15
        }, {
            x: 250,
            y: 120,
            r: 10
        }, {
            x: 400,
            y: 230,
            r: 9
        }, {
            x: 500,
            y: 640,
            r: 12
        } , {
            x: 400,
            y: 150,
            r: 10
        }],
    },
    ],
}"
/>
</div>

</div>
</div>
</div>
</div>
</template>
