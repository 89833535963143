<script setup>
import { onMounted } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    height: {
        type: String,
    default: "300",
    },
    title: {
        type: String,
    default: "",
    },
    description: {
        type: String,
    default: "",
    },
    chart: {
        type: Object,
        required: true,
        labels: Array,
        datasets: {
            type: Array,
            label: String,
            data: Array,
        },
    },
});

onMounted(() => {
    var lineChart = document.getElementById(props.id).getContext("2d");

    let chartStatus = Chart.getChart(props.id);
    if (chartStatus != undefined) {
        chartStatus.destroy();
    }

    new Chart(lineChart, {
        type: 'bar',
        data: {
            labels: props.chart.labels,
            datasets: props.chart.datasets,
        },
        options: {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                },
            },
            interaction: {
                intersect: false,
                mode: "index",
            },
        }
    });
});
</script>
<template>
<div class="card z-index-2">
<div class="pb-0 card-header mb-0">

<div class="row">
<div class="col-md-12">
<h6>{{ props.title }}</h6>
<p v-if="props.description" class="text-sm" v-html="props.description" />
</div>
</div>

</div>
<div class="p-3 card-body">
<div class="chart">
<canvas
:id="props.id"
class="chart-canvas"
:height="props.height"
></canvas>
</div>
</div>
</div>
</template>
